import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Section from "../../components/Section"
import { Body, Header2 } from "../../components/Typography"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Button from "../../components/Button"

const ProfileGrid = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;
  max-width: 816px;
  align-items: start;
  margin: 0 auto;

  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  max-width: 816px;
  margin: 0 auto;
`

function TravisPage({ data }) {
  return (
    <Layout pageTitle="About">
      <Section>
        <ButtonRow>
          <Button url="/about/daniel">
            <FiArrowLeft /> Previous Profile
          </Button>
          <Button url="/about/bob">
            Next Profile <FiArrowRight />
          </Button>
        </ButtonRow>
        <ProfileGrid>
          <GatsbyImage
            image={data.travis.nodes[0].childImageSharp.gatsbyImageData}
          />
          <div>
            <Header2>Dr. J. Travis McDearmon</Header2>
            <br />
            <Body>
              Travis is a fundraiser, writer, researcher, teacher and strategic
              thinker. For the past 15 years, Travis has worked in non-profit
              and higher education advancement including roles in major gifts,
              annual giving, planned giving and donor relations. His 2011
              dissertation, Assessing Dimensions of Alumni Role Identity and the
              Relationship to Institutional Support Behaviors, brought forth a
              new and exciting way of understanding the discrepancy between why
              some graduates interact with their alma mater and others simply
              walk away.
              <br />
              <br />
              Travis has his doctorate in Higher Education from the University
              of Kentucky and is an adjunct faculty member with the Indiana
              University Lilly School of Philanthropy. Travis’ work has been
              published in Research in Higher Education and the International
              Journal of Educational Advancement.
            </Body>
          </div>
        </ProfileGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    travis: allFile(filter: { relativePath: { eq: "travis.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default TravisPage
